<template>
  <div class="bulk-discounts">
    <!-- <alert-modal confirm ref="confirmation" /> -->

    <Card>
      <Bulk :eventUrl="eventUrl" :tableData="bulkTableDatas" :tableIndex="bulkTableIndex"
        :incrementTableIndex="incrementBulkTableIndex" :tiers="tiers" ref="bulkComponent" />
    </Card>
    <div class="divider"></div>
    <div class="row">
      <div class="col full">
        <table-tote v-model="bulkTableDatas" />
      </div>
    </div>
  </div>
</template>

<script>
import AlertModal from "@/components/modals/AlertModal.vue";
import TableTote from "@/components/TableTote.vue";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import dateFormat from "dateformat";
import dayjs from "dayjs";
import Bulk from "../../components/discount/Bulk";

const PROMO_DISCOUNT = "Promo Discount";
const BULK_DISCOUNT = "Bulk Discount";
const BUNDLE_DISCOUNT = "Bundled Discount";
const ALL_TICKET_TIERS = "All Ticket Tiers";

export default {
  components: {
    Bulk,
    TableTote,
    AlertModal
  },
  head() {
    return {
      title: "Bulk Discounts"
    };
  },
  data() {
    return {
      tierList: [{ value: ALL_TICKET_TIERS, label: ALL_TICKET_TIERS }],
      tiers: [],
      eventUrl: this.$route.params.url,
      bulkTableDatas: {},
      bulkTableIndex: 0
    };
  },
  computed: {
    bulkCategoryDiscountTypeDisplay() {
      return this.categoryDiscountTypeButtons[1].checked;
    }
  },
  methods: {
    async updatePromoCode(code, field) {
      try {
        const updatedBulkDiscountActive = { ...code, url: this.eventUrl };
        await this.$axios.post(
          "/planners/update-bulk-code-active",
          updatedBulkDiscountActive
        );
      } catch {
        console.log("error activating bulk ");
      }
    },
    incrementBulkTableIndex() {
      this.bulkTableIndex++;
    },
    getProductNameById(productList, bundleProducts) {
      let products = productList.filter(({ product_id: id1 }) =>
        bundleProducts.some(({ productId: id2 }) => id2 === id1.$oid)
      );

      let productsString = products.map(p => p.title).join(", ");
      return productsString;
    },
    getTierNameById(tierId) {
      if (tierId === ALL_TICKET_TIERS) {
        return ALL_TICKET_TIERS;
      } else {
        let tier = this.tiers.find(t => t.id === tierId);
        return tier.name;
      }
    },
    getTiers(bundleTiers) {
      let tiers = this.tierList.filter(({ value: id1 }) =>
        bundleTiers.some(({ saleDateId: id2 }) => id2 === id1)
      );

      let tiersString = tiers.map(p => p.label).join(", ");
      return tiersString;
    },
    edit(code, field) {
      this.$refs.bulkComponent.edit(code);
      window.scrollTo({ top: 10, behavior: "smooth" });
    }
  },
  async mounted() {
    // this.$refs.confirmation.open({
    //   title: "Maintenance Underway",
    //   msg: "Click okay to go back to the dashboard.",
    //   onConfirm: () => {
    //     this.$loader.stop();
    //     this.active = false;
    //     document.body.style.overflow = "auto";
    //     this.$router.push("/dashboard");
    //   }
    // });
    //  bulk table data
    let bulkDatas = [];

    const response = await this.$axios.post(
      "/planners/bulk-discount-checkout",
      {
        url: this.eventUrl
      }
    );

    if (response.status) {
      const { bulkDiscounts, tiers } = response.data;

      this.tiers = tiers;

      for (const bulk of bulkDiscounts) {
        let type = bulk.method.type.split(" ")[0];
        let discountDisplay = "";

        if (type === "$") {
          discountDisplay = type + bulk.method.value;
        } else {
          discountDisplay = bulk.method.value + type;
        }

        let tierIdDisplay = this.getTierNameById(bulk.tierId);

        const bulkDiscount = {
          categoryName: BULK_DISCOUNT,
          methodType: bulk.method.type,
          methodValue: bulk.method.value,
          discountType: bulk.discount.type,
          discountValue: bulk.discount.value,
          tierId: bulk.tierId,
          tierIdDisplay: tierIdDisplay,
          venueAccessDateId: bulk.venueAccessDateId,
          venueAccessTimeId: bulk.venueAccessTimeId,
          tierType: bulk.tierType,
          tierDate: bulk.tierDate,
          discountAvailable: bulk.discount.available,
          discountRemaining: bulk.discount.remaining,
          startDate: bulk.start_date,
          endDate: bulk.end_date,
          isActive: bulk.isActive,
          edit: true,
          index: this.bulkTableIndex++,

          //table display
          discountDisplay: discountDisplay,
          availableTicketDisplay:
            bulk.discount.remaining + "/" + bulk.discount.available,

          startDateDisplay: dateFormat(
            new Date(bulk.start_date),
            "ddd, mmm dS, yyyy"
          ),
          endDateDisplay: dateFormat(
            new Date(bulk.end_date),
            "ddd, mmm dS, yyyy"
          )
        };

        if (bulk.tierDate && bulk.tierDate.$date) {
          if (bulk.tierType === "Date" || bulk.tierType === "Date and Time") {
            bulkDiscount.ticket_date = dayjs(
              +bulk.tierDate.$date.$numberLong
            ).format("MMM DD, YYYY");
          }

          if (bulk.tierType === "Date and Time") {
            bulkDiscount.ticket_time = dayjs(
              +bulk.tierDate.$date.$numberLong
            ).format("h:mm A");
          }
        }

        bulkDatas.push(bulkDiscount);
      }

      this.bulkTableDatas = {
        title: "Bulk Discounts",
        columns: [
          { title: "Ticket Tier", field: "tierIdDisplay", width: "28%" },
          // { title: "Tier Date", field: "ticket_date", width: "10%" },
          // { title: "Tier Time", field: "ticket_time", width: "8%" },
          {
            title: "Discount",
            field: "methodValue",
            width: "10%",
            isBulkDiscount: true
          },
          {
            title: "Available",
            field: "availableTicketDisplay",
            width: "10%"
          },
          {
            title: "Start Date",
            field: "startDateDisplay",
            width: "15%",
            date: true
          },
          {
            title: "End Date",
            field: "endDateDisplay",
            width: "15%",
            date: true
          },
          {
            title: "Description",
            field: "discountType",
            width: "20%"
          },
          {
            title: "Quantity",
            field: "discountValue",
            width: "10%"
          },
          { title: "Active", field: "isActive", width: "5%", checkbox: true },
          { title: "Edit", field: "edit", width: "5%", icon: true }
        ],
        data: bulkDatas,
        searchable: ["tierIdDisplay", "discountType"],
        callbacks: {
          isActive: this.updatePromoCode,
          edit: this.edit
        },
        icons: {
          edit: (row, column, library) => {
            library.add(faEdit);
            return "edit";
          }
        }
      };
    }
  }
};
</script>

<style></style>
