<template>
  <FormulateForm
    @submit="submit"
    #default="{ isLoading }"
    v-model="formData"
    name="bulkForm"
  >
    <div class="row">
      <div class="col half radio-button-container">
        <FormulateInput
          label="Select Discount Method"
          type="radio"
          :options="methods"
          name="methodType"
          validation="required"
        />
      </div>
      <div class="col half">
        <FormulateInput
          label="Discount"
          type="number"
          :placeholder="methodTypePlaceholderDisplay"
          :validation="methodInputErrorCheck"
          name="methodValue"
        />
      </div>
    </div>

    <div class="row">
      <div class="col half">
        <FormulateInput
          type="select"
          label="Bulk Discount Option"
          :options="discountOption"
          name="discountType"
          placeholder="Please Select"
          validation="required"
        />
      </div>
      <div class="col half">
        <FormulateInput
          :label="discountOptionPlaceholderDisplay"
          type="number"
          validation="required|number|min:1,value"
          name="discountValue"
          :placeholder="discountOptionPlaceholderDisplay"
        />
      </div>
    </div>

    <div class="row">
      <div class="col half">
        <TicketTierSelector
          v-if="isRecurringEvent"
          :error="false"
          :tiers="tiers"
          :hasAllTiers="true"
          v-model="tier"
          @input="addTier($event)"
        />
        <FormulateInput
          v-else
          type="select"
          label="Ticket Tier"
          :options="tierList"
          name="tierId"
          placeholder="Please Select"
          validation="required"
        />
      </div>
      <div class="col half">
        <FormulateInput
          label="# of Bulk Discounts Available"
          type="number"
          validation="required|number|min:1,value"
          name="discountAvailable"
          placeholder="Enter Number"
        />
      </div>
    </div>

    <div class="row">
      <div class="col half">
        <FormulateInput
          type="dateTime"
          label="Start Date"
          :placeholder="dateDisplayPlaceholder"
          validation="required"
          name="startDate"
          :min-date="new Date()"
          :max-date="startDateMaximumDate"
          autocomplete="false"
        />
      </div>
      <div class="col half">
        <FormulateInput
          type="dateTime"
          label="End Date"
          :placeholder="dateDisplayPlaceholder"
          validation="required"
          name="endDate"
          :min-date="endDateMinimumDate"
          :max-date="eventEndDate"
          autocomplete="false"
        />
      </div>
    </div>

    <div class="row" v-if="isEditing">
      <div class="col half">
        <FormulateInput
          class="cancel-button"
          type="button"
          @click="cancelEdit"
          name="cancel"
        />
      </div>
      <div class="col half">
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :name="isLoading ? 'Saving...' : 'Save'"
        />
      </div>
    </div>

    <div class="row" v-else>
      <div class="col half">
        <diyobo-button
          class="back-events"
          @click="redirect"
          type="primary-dark"
          txt="Back to My Events"
        />
      </div>
      <div class="col half">
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :name="isLoading ? 'Submitting...' : 'Submit'"
        />
      </div>
    </div>
  </FormulateForm>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import dateFormat from "dateformat";
import TicketTierSelector from "@/components/TicketTierSelector.vue";

const BULK_DISCOUNT = "Bulk Discount";

const DOLLAR_DISCOUNT = "$ Discount";
const PERCENT_DISCOUNT = "% Discount";

const NUMBER_OF_TICKET_PURCHASE = "Based on Ticket Quantity";
const TOTAL_DOLLAR_PURCHASE = "Based on Purchase Amount ($)";

const ALL_TICKET_TIERS = "All Ticket Tiers";
export default {
  name: "bulk-discount",
  components: {
    DiyoboButton,
    TicketTierSelector
  },
  props: {
    eventUrl: {
      type: String,
      description: "unique url event",
      required: true
    },
    tableData: {
      type: Object,
      description: "data table display",
      required: true
    },
    tableIndex: {
      type: Number,
      desription: "index that can be use in data table",
      required: true
    },
    incrementTableIndex: {
      type: Function,
      description: "increment tableIndex + 1",
      required: true
    }
  },

  data() {
    return {
      formData: {
        methodType: DOLLAR_DISCOUNT,
        methodValue: "",
        discountType: "",
        discountValue: "",
        tierId: "",
        discountAvailable: "",
        startDate: "",
        endDate: ""
      },
      eventEndDate: null,
      methods: [DOLLAR_DISCOUNT, PERCENT_DISCOUNT],
      discountOption: [NUMBER_OF_TICKET_PURCHASE, TOTAL_DOLLAR_PURCHASE],
      discountOptionPlaceholder: "",
      tierList: [{ value: ALL_TICKET_TIERS, label: ALL_TICKET_TIERS }],
      dateDisplayPlaceholder: dateFormat(
        new Date(Date.now()),
        "ddd, mmm dS, yyyy"
      ),
      isEditing: false,

      //previousBulkCode used for editing tableData and matching in database
      previousBulkDiscount: {},
      tiers: [],
      tier: null,
      isRecurringEvent: false
    };
  },

  computed: {
    startDateMaximumDate() {
      return this.formData.endDate !== ""
        ? this.formData.endDate
        : this.eventEndDate;
    },
    endDateMinimumDate() {
      return this.formData.startDate === "" || this.formData.startDate === null
        ? new Date()
        : this.formData.startDate;
    },
    methodTypePlaceholderDisplay() {
      return this.formData.methodType === DOLLAR_DISCOUNT
        ? "Discount ($)"
        : "Discount (%)";
    },
    methodInputErrorCheck() {
      return this.formData.methodType === DOLLAR_DISCOUNT
        ? "required|number|min:1,value"
        : "required|number|min:1,value|max:100, value";
    },
    discountOptionPlaceholderDisplay() {
      return (this.discountOptionPlaceholder =
        this.formData.discountType === ""
          ? "Please Select Bulk Discount Option"
          : this.formData.discountType === NUMBER_OF_TICKET_PURCHASE
          ? "# of Tickets Eligible"
          : "Total Eligible Amount ($)");
    }
  },
  methods: {
    addTier(event) {
      console.log(event);
      this.formData.tierId = event.tierID;
    },
    async submit() {
      try {
        let endPoint = this.isEditing
          ? "/planners/update-bulk-discount"
          : "/planners/add-bulk-discount";

        let bulkDiscount = {};

        if (this.isEditing) {
          const updatedBulkDiscount = this.createBulkDiscount(
            this.formData,
            true
          );
          bulkDiscount = {
            previousBulkDiscount: this.previousBulkDiscount,
            updatedBulkDiscount: updatedBulkDiscount
          };
        } else {
          bulkDiscount = this.createBulkDiscount(this.formData, true);
        }

        const response = await this.$axios.post(endPoint, bulkDiscount);
        if (response.status === 200) {
          const { data } = response;
          let bulkDiscountTableDisplay = this.createBulkDiscountTableDisplay(
            data
          );

          if (this.isEditing) {
            const index = this.previousBulkDiscount.index;
            bulkDiscountTableDisplay.index = index;
            this.tableData.data.splice(index, 1, bulkDiscountTableDisplay);
          } else {
            this.tableData.data.push(bulkDiscountTableDisplay);
            this.incrementTableIndex();
          }

          let msg = this.isEditing
            ? "Updated Bulk Discount"
            : "Bulk Discount Successfully Created";
          this.$toast.success(msg);
        }
      } catch (error) {
        console.log(error);
        console.log("error submiting the form");
      } finally {
        this.clearFormData();
      }
    },
    edit(code) {
      this.isEditing = true;
      this.populateFormData(code);
      this.previousBulkDiscount = this.createBulkDiscount(code, false);
    },
    createBulkDiscount(bulk, isFormData) {
      let tempbulkDiscount = {
        methodType: bulk.methodType,
        methodValue: parseInt(bulk.methodValue),
        discountType: bulk.discountType,
        discountValue: parseInt(bulk.discountValue),
        tierId: bulk.tierId,
        discountAvailable: parseInt(bulk.discountAvailable),
        startDate: bulk.startDate,
        endDate: bulk.endDate,
        isActive: true,
        url: this.eventUrl,
        tierType: this.tier ? this.tier.type : null,
        venueAccessDateID: this.tier ? this.tier.venueAccessDateID : null,
        venueAccessTimeID: this.tier ? this.tier.venueAccessTimeID : null
      };
      return isFormData
        ? {
            ...tempbulkDiscount,
            discountRemaining: parseInt(bulk.discountAvailable)
          }
        : {
            ...tempbulkDiscount,
            index: bulk.index,
            discountRemaining: parseInt(bulk.discountRemaining)
          };
    },
    createBulkDiscountTableDisplay(bulk) {
      let type = bulk.methodType.split(" ")[0];
      let discountDisplay = "";

      if (type === "$") {
        discountDisplay = type + bulk.methodValue;
      } else {
        discountDisplay = bulk.methodValue + type;
      }

      let tierIdDisplay = this.getTierNameById(bulk.tierId);
      return {
        categoryName: BULK_DISCOUNT,
        methodType: bulk.methodType,
        methodValue: bulk.methodValue,
        discountType: bulk.discountType,
        discountValue: bulk.discountValue,
        tierId: bulk.tierId,
        tierIdDisplay: tierIdDisplay,
        discountAvailable: bulk.discountAvailable,
        discountRemaining: bulk.discountRemaining,
        startDate: bulk.startDate,
        endDate: bulk.endDate,
        isActive: bulk.isActive,
        edit: true,
        index: this.tableIndex,

        //table display
        discountDisplay: discountDisplay,
        availableTicketDisplay:
          bulk.discountAvailable + "/" + bulk.discountAvailable,

        startDateDisplay: dateFormat(
          new Date(bulk.startDate),
          "ddd, mmm dS, yyyy"
        ),
        endDateDisplay: dateFormat(new Date(bulk.endDate), "ddd, mmm dS, yyyy")
      };
    },
    redirect() {
      this.$router.push("/my-events");
    },
    cancelEdit() {
      this.clearFormData();
    },
    clearFormData() {
      this.$formulate.reset("bulkForm");
      this.formData.methodType = DOLLAR_DISCOUNT;
      this.formData.methodValue = "";
      this.formData.discountType = "";
      this.formData.discountValue = "";
      this.formData.tierId = "";
      this.formData.discountAvailable = "";
      this.formData.startDate = "";
      this.formData.endDate = "";
      this.isEditing = false;
      this.previousBulkDiscount = {};
    },
    populateFormData(bulk) {
      this.formData.methodType = bulk.methodType;
      this.formData.methodValue = bulk.methodValue;
      this.formData.discountType = bulk.discountType;
      this.formData.discountValue = bulk.discountValue;
      this.formData.tierId = bulk.tierId;
      this.formData.discountAvailable = bulk.discountRemaining;
      this.formData.startDate = bulk.startDate;
      this.formData.endDate = bulk.endDate;
      this.tier = {
        type: bulk.tierType,
        tierID: bulk.tierId,
        venueAccessDateID: bulk.venueAccessDateId,
        venueAccessTimeID: bulk.venueAccessTimeId
      };
    },
    getTierNameById(tierId) {
      let tier = this.tierList.find(t => t.value === tierId);
      return tier.label;
    }
  },
  async mounted() {
    try {
      const response = await this.$axios.post(
        "/planners/bulk-discount-checkout",
        {
          url: this.eventUrl
        }
      );
      this.tiers = response.data.tiers;
      this.isRecurringEvent = response.data.isRecurring;
      for (const tier of this.tiers) {
        this.tierList.push({
          value: tier.id,
          label: tier.name
        });
      }
    } catch {
      console.log("cannot fetch the data");
    }
  }
};
</script>

<style lang="less">
.radio-button-container {
  .formulate-input-element {
    display: flex;
  }
}

.back-events {
  margin-left: auto;
}

.formulate-input {
  &[data-classification="submit"] button {
    margin-left: initial;
  }
}
</style>
